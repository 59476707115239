
import { defineComponent } from "vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";

export default defineComponent({
  name: "UserRanking",
  components: {
    AvatarCircle
  },
  props: {
    data: { type: Array },
    title: { type: String }
  },
  setup() {
    return {
      getFilePathFromUrl,
    };
  },
});
