
// @ is an alias to /sr
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import CircularChart from "@/components/atomics/CircularChart.vue";
import AccessCodePopup from "@/components/popups/sign/AccessCodePopup.vue";
import BarChart from "@/components/atomics/chart/BarChart.vue";
import UserRanking from "@/components/atomics/ranking/UserRanking.vue";
import FileRanking from "@/components/atomics/ranking/FileRanking.vue";
import {
  AccountRepository,
  ContractRepository,
  RepositoryFactory,
} from "@/lib/https";
import { formatFolderPath } from "@/lib/utility/common";
import moment from "moment";
import { storage } from "@/lib/storage";
import { useStore } from "vuex";
import { useForm } from "vee-validate";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

enum NOTIFICATION_TYPE {
  CONTRACT = 1,
  TEMPLATE = 2,
  CONTRACT_FOLDER = 3,
  TEMPLATE_FOLDER = 4,
  GROUP = 5,
}

export default defineComponent({
  name: "DashBoard",
  components: {
    AfterLoginPageLayout,
    FlatButton,
    CircularChart,
    AccessCodePopup,
    BarChart,
    UserRanking,
    FileRanking,
  },
  setup() {
    const { t } = useI18n();
    const state = reactive({
      notifications: [],
      summary: {},
      userFullName: "",
      currentClickContractId: "",
      isShowAccessCodePopup: false,
      bannerText: "",
      contractStatus: [] as any,
      statusOptions: [
        {
          id: 1,
          name: t("folder.filter1"),
          class: "confirm-request",
          event: () => router.push({ name: "Folder", query: { status: "confirm-request" } }),
        },
        {
          id: 2,
          name: t("folder.filter2"),
          class: "checking",
          event: () => router.push({ name: "Folder", query: { status: "checking" } }),
        },
        {
          id: 3,
          name: t("folder.filter3"),
          class: "concluded",
          event: () => router.push({ name: "Folder", query: { status: "concluded" } }),
        },
        {
          id: 0,
          name: t("folder.filter0"),
          class: "draft",
          event: () => router.push({ name: "Folder", query: { status: "draft" } }),
        },
        {
          id: 4,
          name: t("folder.filter4"),
          class: "rejected",
          event: () => router.push({ name: "Folder", query: { status: "rejected" } }),
        },
      ],
      loaded: false,
      chartColor: [
        "#121554", "#1d3681", "#2545A6", "#2F57CF", "#2e70a7", "#3C89C8", "#63A1D3",
        "#4eadc7", "#74BFD2", "#9AD0DE", "#a7d8bf", "#CAE7D8", "#EDF7F2",
      ],
      chartOptions: {
        plugins: {
          title: {
            display: true,
            text: t("dashboard.barChartTitle"),
            align: "start",
            font: {
              weight: "bold",
              size: 16
            }
          },
          legend: {
            display: false
          },
        },
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true
          }
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      chartData: {
        labels: [],
        datasets: [],
      } as any,
      averageElapsedTime: 0,
      rankingContractData: [] as any,
      rankingSignData: [] as any,
      rankingTemplateData: [] as any,
    });

    const {
      getDashboard,
      getStatistics,
      getElapsedTime,
      getRanking
    } = dashboardMethods();

    const store = useStore();
    const accountInfo = store.getters["header/getAccountInfo"];

    const updatedDate = (date: string) => {
      const day = new Date(date);
      return moment(day).format("YYYY/MM/DD");
    };

    const deadlineDate = (date: string) => {
      const day = new Date(date);
      moment.locale("ja");
      return moment(day).format("YYYY年M月D日");
    };

    const { getContractFullInformation } =
      RepositoryFactory.getRepository<ContractRepository>(ContractRepository);
    const { setFieldError } = useForm();
    const localStorage = storage.getLocalStorage();

    const router = useRouter();

    const handleClickContract = async (contractId: string) => {
      try {
        const accessCode = localStorage.get(contractId) || "";
        const contract = await getContractFullInformation(
          contractId,
          accessCode
        );
        if (contract) return true;
      } catch (e) {
        state.currentClickContractId = contractId;
        state.isShowAccessCodePopup = true;
        return false;
      }
    };

    const handleInputAccessCode = async (accessCode: string) => {
      try {
        const contract = await getContractFullInformation(
          state.currentClickContractId,
          accessCode
        );
        if (contract) {
          state.isShowAccessCodePopup = false;
          localStorage.add(state.currentClickContractId, accessCode);
          router.push({
            name: "SignDetails",
            query: {
              contractId: state.currentClickContractId,
            },
          });
        }
      } catch (e: any) {
        setFieldError("accessCode", e.data.message);
      }
    };

    const hour = new Date().getHours();
    if( 5 <= hour && hour <= 9 ) {
      state.bannerText = t("dashboard.bannerText1");
    }
    else if( ( 17 <= hour && hour <= 23 ) || ( 0 <= hour && hour <= 4 ) ) {
      state.bannerText = t("dashboard.bannerText3");
    }
    else {
      state.bannerText = t("dashboard.bannerText2");
    }

    const setSignerName = (index: number) => {
      var count = Math.floor(index / 26);
      var name = String.fromCharCode((index - (count * 26)) + 65);
      if (count > 0) {
        name = String.fromCharCode(count + 65 - 1) + name;
      }
      return name;
    };

    onMounted(async () => {
      const payload = await getDashboard();
      state.notifications = payload.notifications;
      state.summary = payload.summary;

      const localStorage = storage.getLocalStorage();
      state.userFullName = localStorage.get("userFullName") ?? "";

      setTimeout(() => {
        state.userFullName = localStorage.get("userFullName") ?? "";
      }, 200);

      // Statistics
      const statusData = await getStatistics();

      state.statusOptions.forEach((element: any) => {
        const target = statusData.find((v: any) => v.status === element.id);
        state.contractStatus.push({
          id: element.id,
          name: element.name,
          class: element.class,
          count: target ? target.count : 0,
          event: element.event
        });
      });

      // Chart
      const chartDataResponse = await getElapsedTime() ?? [];

      let totalTime = 0;
      let colorIndex = 0;
      let signerIndex = 0;
      chartDataResponse.forEach((element: any, index: number) => {
        const contractTitle = t("dashboard.labelContract") + (index + 1);
        state.chartData.labels.push(contractTitle);

        element.signers.forEach((signer: any) => {
          const datasetsIndex = state.chartData.datasets.findIndex((v: any) => v.signerName === signer.name);
          if (datasetsIndex < 0) {
            const timeData = [];
            if (index > 0) {
              for (let step = 0; step < index; step++) {
                timeData.push(0);
              }
            }
            timeData.push(signer.time);

            state.chartData.datasets.push({
              signerName: signer.name,
              label: t("dashboard.labelSigner") + setSignerName(signerIndex),
              backgroundColor: state.chartColor[colorIndex],
              data: timeData,
            });

            signerIndex++;
            colorIndex++;
            if (typeof state.chartColor[colorIndex] !== "string") { colorIndex = 0; }
          }
          else {
            state.chartData.datasets[datasetsIndex].data.push(signer.time);
          }
        });

        state.chartData.datasets.forEach((item: any) => {
          if (item.data.length != (index + 1)) {
            item.data.push(0);
          }
        });

        totalTime += element.total;
      });
      state.averageElapsedTime = (Math.floor((totalTime / chartDataResponse.length) * 10)) / 10;

      // Ranking
      state.rankingContractData = await getRanking('created');
      state.rankingSignData = await getRanking('signed');
      state.rankingTemplateData = await getRanking('template');

      state.loaded = true;
    });

    return {
      ...toRefs(state),
      updatedDate,
      deadlineDate,
      formatFolderPath,
      accountInfo,
      handleClickContract,
      handleInputAccessCode,
      NOTIFICATION_TYPE,
    };
  },
  methods: {
    onClickPremium(): void {
      this.$router.push("/pricing-plan");
    },
    async handleCompletedContract(item: any) {
      if (await this.handleClickContract(item.id))
        this.$router.push({
          name: "SignDone",
          query: {
            contractId: item.id,
          },
        });
    },
    async handleNeedToSignContract(item: any) {
      if (await this.handleClickContract(item.id))
        this.$router.push({
          name: "SignDetails",
          query: {
            contractId: item.id,
          },
        });
    },
  },
});

const dashboardMethods = () => {
  const { getDashboard, getStatistics, getElapsedTime, getRanking } =
    RepositoryFactory.getRepository<AccountRepository>(AccountRepository);

  return { getDashboard, getStatistics, getElapsedTime, getRanking };
};
