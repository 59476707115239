
import { defineComponent } from "vue";
import { formatDate } from "@/lib/utility/common";

export default defineComponent({
  name: "FileRanking",
  props: {
    data: { type: Array },
    title: { type: String }
  },
  setup() {
    return {
      formatDate,
    };
  },
});
