
import { defineComponent, reactive, toRefs } from "vue";

export default defineComponent({
  name: "CircularChart",
  props: {
    percentage: { type: Number, required: true, default: 0 },
    currentMonth: { type: String, default: "" },
  },
  setup(props) {
    const state = reactive({
      animatedValue: 0,
      duration: 1000,
      lastDrawTime: 0,
    });

    return {
      ...toRefs(state),
    };
  },
  mounted() {
    var c = this.$refs.canvas as HTMLCanvasElement;
    var ctx = c.getContext("2d") as CanvasRenderingContext2D;
    c.width = this.$el.clientWidth > 0 ? this.$el.clientWidth : c.width;
    c.height = this.$el.clientHeight > 0 ? this.$el.clientHeight : c.height;
    ctx.translate(0.5, 0.5);

    this.draw(c, ctx);
  },
  methods: {
    draw(c: HTMLCanvasElement, ctx: CanvasRenderingContext2D) {
      ctx.beginPath();
      ctx.fillStyle = "#F0F0F0";

      const val = (Math.min(c.width, c.height) - ctx.lineWidth) / 2;
      ctx.arc(c.width / 2, c.height / 2, val > 0 ? val : 0, 0, Math.PI * 2);
      ctx.fill();

      setTimeout(() => {
        this.lastDrawTime = Date.now();
        this.drawProgress(c, ctx);
      }, 500);
    },

    drawProgress(c: HTMLCanvasElement, ctx: CanvasRenderingContext2D) {
      ctx.beginPath();
      ctx.lineWidth = 20;

      ctx.strokeStyle = this.percentage <= 100 ? "#D64D10" : "#D00D41";

      var timeLapsed = Date.now() - this.lastDrawTime;
      if (timeLapsed > this.duration) timeLapsed = this.duration;

      var value = this.interpolation(timeLapsed / this.duration);
      var endAngle =
        ((Math.PI * 2 * this.percentage) / 100) * value - Math.PI / 2;
      this.animatedValue = Math.floor(this.percentage * value);

      const val = (Math.min(c.width, c.height) - ctx.lineWidth) / 2;
      ctx.arc(
        c.width / 2,
        c.height / 2,
        val > 0 ? val : 0,
        -Math.PI / 2,
        endAngle,
        false
      );
      ctx.stroke();

      if (timeLapsed < this.duration) {
        window.requestAnimationFrame(() => this.drawProgress(c, ctx));
      }
    },

    interpolation(value: number): number {
      return Math.sin((value * Math.PI) / 2);
    },
  },
});
